.step {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    color: var(--dark-charcoal);
}

.stepContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.stepBlocked {
    font-weight: 400;
    color: var(--dark-charcoal);
    opacity: 0.5;
}

.iconWrapper {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconWrapper svg {
    width: 20px;
    height: 20px;
}