@import "colors.css";

.title {
    color: var(--dark-charcoal);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 16px;
}

.separator {
    border: none;
    height: 2px;
    background-color: var(--light-grayish-blue);
    margin: 24px 0;
}

.taskContainer {
    display: flex;
    flex-direction: column;
    margin-right: 80px;
    gap: 16px
}

@media (max-width: 767px) {
    .taskContainer {
        margin-right: 0;
    }
}

.accordionContainer {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.completedTaskRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 767px) {
    .completedTaskRow {
        flex-direction: column;
        align-items: flex-start;
    }
}

.dateCompleted {
    font-size: 14px;
    color: var(--dark-charcoal);
    opacity: 0.5;
    min-width: 100px;
    text-align: right;
}

@media (max-width: 767px) {
    .dateCompleted {
        text-align: left;
        margin-left: 32px;
    }
}
