@import "colors.css";

.selectContainer {
    font-size: 13px;
    width: 100% !important;
}

.selectContainer.noLabel {
    margin-top: 18px;
}

.selectContainer .required {
    display: inline-block;
    color: var(--pastel-red);
    font-size: 13px;
    line-height: 18px;
    margin-left: 1px;
}

.selectWrapper {
    margin-top: 10px;
}

.select {
    border: solid 1px var(--light-grayish-blue) !important;
    padding: 16px !important;
    height: 52px;
    white-space: nowrap;
}

.select.notValid {
    border-color: var(--pastel-red) !important;
}

.select:hover {
    border-color: var(--chinese-silver) !important;
}

.select.notValid:hover {
    border-color: var(--pastel-red) !important;
}

.select:focus {
    border: solid 2px var(--electric-blue) !important;
    box-shadow: none !important;
}

.select.notValid:focus {
    border: solid 2px var(--pastel-red) !important;
    box-shadow: none !important;
}

.select:focus-within {
    border: solid 2px var(--electric-blue) !important;
}

.select.disabled {
    border-color: var(--gainsboro);
    background-color: var(--chinese-silver);
    font-size: 13px;
}

.selectDropdownHeader {
    position: relative;
}

.searchIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.searchInput,
.searchInput:hover,
.searchInput:focus {
    border: none !important;
    outline: none !important;
}

.searchInput {
    display: block;
    min-height: 40px;
    width: 100%;
    padding: 5px 10px 5px 30px;
    line-height: 30px;
}

.selectDropdownBody {
    max-height: 200px;
    overflow-y: auto;
}

.selectDropdownItem {
    min-height: 40px;
    padding: 5px 10px;
    line-height: 30px;
    cursor: pointer;
}

.selectDropdownItem p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectField {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    min-width: 0;
}

.select :global(.react-dropdown-select-content.react-dropdown-select-type-single) {
    width: calc(100% - 27px)
}