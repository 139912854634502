.fileContainer {
    background-color: var(--frost-white);
    display: flex;
    height: 50px;
    padding: 16px;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    justify-content: space-between;
    position: relative;
}

.fileContent {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 100%;
    flex: 1;
    min-width: 0;
}

.cross,
.cross path {
    fill: var(--dark-charcoal);
    stroke-width: 2;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
}

.cross:hover {
    cursor: pointer;
}

.file {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 13px;
    text-decoration: none;
    cursor: default;
}

.fileLink {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 13px;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.fileLink:hover {
    text-decoration: underline;
}
