@import "colors.css";

.doc {
    display: flex;
    background-color: var(--frost-white);
    border-radius: 8px;
    padding: 16px;
    gap: 5px;
    align-items: center;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
}