:root {
  --white: #FFFFFF;
  --black: #000000;
  
  --badge-primary-background: #E0BBF8;
  --badge-primary-text: #5D009A;
  --badge-neutral-background: #D3D3D3;
  --badge-neutral-text: #555555;
  --badge-error-background: #FDDEDE;
  --badge-error-text: #A31515;
  --badge-warning-background: #FEF3C7;
  --badge-warning-text: #92400E;
  --badge-success-background: #BBF7D0;
  --badge-success-text: #14532D;
  
  --deep-midnight-blue: #16123F;
  --vivid-red: #F03E3D;
  --light-red: #FFE6E6;
  --light-grayish-blue: #E9EBEF;
  --dark-charcoal: #223232;
  --soft-silver: #E5E7EB;
  --sky-blue: #4A90E2;
  --sky-blue-80: #4A90E280;
  --bright-gray: #E9E9EA;
  --philippine-gray: #8D8D8D;
  --android-green: #A6CE3A;
  --middle-yellow: #FFEC02;
  --warm-yellow: #FCD34D;
  --ucla-gold: #FFB600;
  --mahogany: #BA3A02;
  --carmine: #990811;
  --muted-green: #D7DADA;
  --teal-green: #0EAD79;
  --forest-green: #007C10;
  --emerald-green: #34C759;
  --deep-quamarine-green: #428A66;
  --mint-green: #9BCCA2;
  --pale-mint-green: #F6FEF7;
  --pastel-mint-green: #A9E6B0;
  --soft-shade-green: #7FB88B;
  --mint-frost: #E3F3E5;
  --sunshine-yellow: #FDE68A;
  --ivory-yellow: #FFFBEB;
  --space-cadet: #1E1B4B;
  --warm-ivory: #FFFBEB;
  --ghost-white: #F9FAFC;
  --gray: #7D7D7E;
  --anti-flash-white: #F3F4F6;
  --lavender-web: #E0E7FF;
  --lilac-mist: #EDE9FE;
  --grape: #5B21B6;
  --grape-transparent: #5B21B619;
  --light-gray: #D4D4D4;
  --indigo-blue: #6366F1;
  --soft-gray: #B3B3B3;
  --coral-rose: #F43F5E;
  --urban-gray: #969798;
  --electric-blue: #2F80ED;
  --gainsboro: #DADADA;
  --rasin-black: #232323;
  --chinese-silver: #CCCCCC;
  --granite-gray: #605F5F;
  --royal-indigo: #4C5DEE;
  --lavender-haze: #E4E7FC;
  --blush-pink: #FDE2E7;
  --pastel-red: #F86060;
  --red: #FF4B36;
  --brick-red: #AC3123;
  --argent: #BFBFBF;
  --royal-blue: #1E30D3;
  --ash-gray: #B6BDBD;
  --color-gray-dark: #CCCCCC;
  --color-shadow: rgba(0, 0, 0, 0.25);
  --plate-shadow: rgba(20, 46, 110, 0.1);
  --caded-blue: #B2B9C7;
  --chinese-white: #E1E1E1;
  --lotion: #FAFAFA;
  --whisper-gray: #3B3B3B4D;
  --carnelian: #B71C1C;
  --charcoal: #373333;
  --eco-green: #26AA57;
  --pale-silver: #ECECEC;
  --tangerine: #F79009;
  --dark-cyan: #009688;
  --crayola: #F9A825;
  --dark-candy-apple-red: #A60505;
  --platinum: #E1E1E3;
  --lavender-purple: #AC5AED;
  --bright-orchid: #FA95FA;
  --pale-aqua: #D8F0ED;
  --pale-sky-blue: #DFECFC;
  --pale-orchid: #FEEFFE;
  --lavender-mist: #F3E6FC;
  --azureish-white: #DEF2E7;
  --neutral-gray: #808080;
  --seashell: #F1F1F1;
  --iron-gray: #7F7F7F;
  --frost-white: #FAFBFD;
  --moody-purple: #1E1B4BCC;
  --sapphire-blue: #1A56DB;
  --spanish-gray: #979797;
  --muted-gray: #929999;
  --silver-gray: #CCCCCC;
  --dusty-violet: #504D6F;
  --ice-mist: #F2F6F9;
}
