@import "colors.css";

.wrapper {
    width: 100%;
    min-width: 300px;
}

.dropZone {
    border: 1px dashed var(--gainsboro);
    border-radius: 4px;
    padding: 24px;
    cursor: pointer;
    text-align: center;
    position: relative;
    margin-top: 12px;
    transition: background-color 0.2s ease;
}

.dropZoneActive {
    background-color: var(--seashell);
}

.dropZoneContent {
    background-color: var(--ghost-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 300px;
    height: 140px;
    margin: 0 auto;
    border-radius: 2px;
    border: 1px solid var(--light-grayish-blue);
}

.uploadIcon {
    width: 24px;
    height: 24px;
    fill: var(--dark-charcoal);
}

.uploadText {
    color: var(--iron-gray);
    font-size: 14px;
    margin: 0;
}

.formatsText {
    color: var(--iron-gray);
    font-size: 12px;
}

.hiddenInput {
    display: none;
}

.label {
    font-size: 12px;
    font-weight: 400;
    margin: 8px 0;
    text-transform: uppercase;
}

.filesList {
    margin-bottom: 8px;
}

.error {
    color: var(--pastel-red);
    font-size: 12px;
    margin-top: 4px;
}