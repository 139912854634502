@import 'colors.css';

.fileUploadContainer {
  font-size: 13px;
  margin-top: 20px;
}

.fileUploadContainer .required {
  display: inline-block;
  color: var(--pastel-red);
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-left: 1px;
}

.fileUpload {
  border: 1px dashed var(--gainsboro);
  padding: 33px 24px;
  position: relative;
}

.fileUpload.notValid {
  border-color: var(--pastel-red);
}

.fileUpload.dropSignal {
  border: 3px dashed var(--chinese-silver) !important;
}

.fileUpload > .uploadBtn,
.fileUpload > .selected {
  border-radius: 2px;
  border: 1px solid var(--light-grayish-blue);
  background: var(--ghost-white);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  width: max-content;
  margin: 0 auto;
  max-width: 100%;
}

.fileUpload > .uploadBtn {
  border: 1px solid var(--gainsboro);
}

.fileUpload > .selected a {
  word-break: break-word;
}

a.selected > span {
  text-decoration: underline;
}

.fileUpload > .uploadBtn > *,
.fileUpload > .selected > * {
  opacity: 0.5;
}

.fileUpload > .selected svg {
  color: var(--deep-midnight-blue);
}

.fileUpload > .uploadBtn > span {
  line-height: 185%;
}

.fileUpload .description {
  text-align: center;
  opacity: 0.5;
  margin-top: 6px;
  line-height: 123%;
}

.fileUpload > .clearBtn {
  --size: 16px;
  border: 1px solid var(--rasin-black);
  width: var(--size);
  height: var(--size);
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 16px;
  top: 12px;
}

.fileUpload.disabled {
  background: var(--lotion);
  border-color: var(--light-grayish-blue) !important;
}
