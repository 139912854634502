.insuranceFlexLine {
  flex-wrap: wrap;
  gap: 24px 28px;
  margin-top: 24px;
}

.insuranceFlexLine:last-child {
  margin-bottom: 24px;
}

.insuranceInputGeneric {
  width: 271px !important;
  max-width: 100%;
}
