.inputLoader {
  max-width: 27rem;
  width: 100%;
}

.loaderLabel {
  height: 1.8rem;
  width: 50%;
  margin-bottom: 1rem;
  background: var(--light-grayish-blue);
}

.loaderInput {
  height: 4.8rem;
  background: var(--light-grayish-blue);
}

@media only screen and (max-width: 480px) {
  .inputLoader {
    max-width: initial;
  }
}
