.row {
  display: grid;
  grid-template-columns: 280px 100px 50px 150px 100px auto;
  align-items: center;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--frost-white);
  color: var(--dark-charcoal);
  min-width: 800px;
  width: 820px;
  margin: 6px 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.name {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;
  font-size: 16px;
}

.link {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 6px;
  max-width: 90%;
  font-size: 13px;
}

.link>span:hover {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.type,
.uploadedBy,
.uploadTime {
  font-size: 13px;
  color: #555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteButton {
  background: none;
  border: none;
  font-size: 14px;
  transition: color 0.2s;
}

.deleteButton svg {
  cursor: pointer;
  margin-left: 4px;
}